import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { STArea } from "./STArea";
import { STSensor } from "./STSensor";
import { STSeveridad } from "./STSeveridad";
import { STTipoEvento } from "./STTipoEvento";
import { STAmbiente } from "./STAmbiente";
import { DateRangePicker } from "materialui-daterange-picker";
import { STDinamicAmbiente } from "./STDinamicAmbiente";

export const Statistics = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openA = Boolean(anchorEl);
  const [tipoTicket, setTipoTicket] = useState("");
  const [dateRange, setDateRange] = useState({});

  const toggle = () => setOpen(!open);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="start"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12}>
        <STDinamicAmbiente
          dateRange={dateRange}
          tipoTicket={tipoTicket}
        ></STDinamicAmbiente>
      </Grid>
      <Grid item xs={12}>
        <STArea dateRange={dateRange} tipoTicket={tipoTicket}></STArea>
      </Grid>
      <Grid item xs={12} md={6}>
        <STSeveridad
          dateRange={dateRange}
          tipoTicket={tipoTicket}
        ></STSeveridad>
      </Grid>
      <Grid item xs={12} md={6}>
        <STTipoEvento
          dateRange={dateRange}
          tipoTicket={tipoTicket}
        ></STTipoEvento>
      </Grid>
      <Grid item xs={12}>
        <STSensor dateRange={dateRange} tipoTicket={tipoTicket}></STSensor>
      </Grid>
      <Grid item xs={12}>
        <STAmbiente dateRange={dateRange} tipoTicket={tipoTicket}></STAmbiente>
      </Grid>
      {/* <Grid item xs={12}>
        <STPlatform dateRange={dateRange}></STPlatform>
      </Grid> */}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="volver"
        onClick={toggle}
        style={{
          margin: 0,
          top: "auto",
          right: 160,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        Establecer rango de fecha
      </Fab>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Tipo ticket"
        onClick={handleClick}
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        Tipo ticket
      </Fab>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openA}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setTipoTicket("");
          }}
        >
          Todos los eventos
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setTipoTicket("Ticket");
          }}
        >
          Ticket
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setTipoTicket("CCT");
          }}
        >
          CCT
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setTipoTicket("Informativo");
          }}
        >
          Informativo
        </MenuItem>
      </Menu>
      <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">
          {"Selecciona el rango de fecha"}
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range) => setDateRange(range)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
