import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  useGetEnvironmentStatisticsQuery,
  useLazyGetEnvironmentSensorsStatisticsQuery,
} from "../../features/api/apiYeapGo";
import { selectUser } from "../../features/user/userSlice";
import Moment from "moment";
import { useEffect, useState } from "react";

export const STDinamicAmbiente = ({ dateRange, tipoTicket }) => {
  const [selection, setSelection] = useState("");
  const [sensores, setSensores] = useState([]);
  const [date, setDate] = useState({
    fechaInicial: "2020-1-01",
    fechaFinal: "3000-12-01",
  });
  const { Token } = useSelector(selectUser);
  const { data, isLoading } = useGetEnvironmentStatisticsQuery({
    Token,
    ...date,
    TipoTicket: tipoTicket,
  });

  const [trigger, { data: dataSensor }] =
    useLazyGetEnvironmentSensorsStatisticsQuery({});

  useEffect(() => {
    if (dateRange.startDate) {
      setDate({
        fechaInicial: Moment(dateRange.startDate).format("YYYY-MM-DD"),
        fechaFinal: Moment(dateRange.endDate).format("YYYY-MM-DD"),
      });
    }
  }, [dateRange]);
  useEffect(() => {
    if (selection !== "") {
      trigger({
        Token,
        ...date,
        ambiente: selection,
        TipoTicket: tipoTicket,
      });
    }
  }, [selection, date, tipoTicket]);

  useEffect(() => {
    if (dataSensor) {
      let d = [...dataSensor];
      d.sort((a, b) => {
        return b.Alertas - a.Alertas;
      });
      setSensores(d.slice(0, 5));
    }
  }, [dataSensor]);
  const state = {
    series: data?.map((d) => d.Alertas) || [],
    options: {
      chart: {
        type: "donut",
        events: {
          dataPointSelection: function (e, chart, opts) {
            setSelection(data[opts.dataPointIndex].Ambiente);
          },
        },
        toolbar: {
          autoSelected: "zoom",
          show: true,
          tools: {
            download: true,
          },
        },
      },
      labels: data?.map((d) => d.Ambiente || "") || [],
      theme: {
        mode: "light",
        palette: "palette1",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const stateGraph2 = {
    series: [
      {
        name: "Número de gestionados",
        data: sensores?.map((d) => d.Alertas) || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      theme: {
        mode: "light",
        palette: "palette4",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
          horizontal: true,
        },
      },

      dataLabels: {
        enabled: true,
        offsetX: 50,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      legend: {
        show: false,
      },

      xaxis: {
        categories: sensores?.map((d) => d.Sensor || "Null") || [],
      },
    },
  };
  return (
    <Accordion defaultExpanded={true} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography gutterBottom variant="h5" component="div">
          Estadisticas de gestión por ambiente
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <>
            {data.length > 0 ? (
              <>
                <Chart
                  options={state.options}
                  series={state.series}
                  type="donut"
                  height={"340rem"}
                />
                {dataSensor ? (
                  <Chart
                    options={stateGraph2.options}
                    series={stateGraph2.series}
                    type="bar"
                    height={"340rem"}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>No hay datos</>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
